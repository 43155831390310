<script setup></script>

<template>
  <div class="layout-clean">
    <GlobalErrorHeader class="solid" />

    <slot />
  </div>
</template>

<style lang="postcss"></style>
